import { render, staticRenderFns } from "./LocationDashboardBulkActionsModal.vue?vue&type=template&id=2117c3a7&scoped=true&"
import script from "./LocationDashboardBulkActionsModal.vue?vue&type=script&lang=ts&"
export * from "./LocationDashboardBulkActionsModal.vue?vue&type=script&lang=ts&"
import style0 from "./LocationDashboardBulkActionsModal.vue?vue&type=style&index=0&id=2117c3a7&scoped=true&lang=sass&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2117c3a7",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSimpleCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VTooltip } from 'vuetify/lib/components/VTooltip';
installComponents(component, {VAvatar,VCardActions,VCardText,VCardTitle,VCol,VIcon,VRow,VSimpleCheckbox,VTooltip})
