import { AbstractRepository, ApiPagination } from '@/repositories/abstract-repository';
import {
    ActionItemGroupType,
    ActionItemsResults,
    ActionItemsSortParameter,
    ActionTaskDate
} from '../models/action-items-models';
import qs from 'qs';

export interface ActionItemsParams {
    tasks_dash_mode?: boolean;
    non_tasks_dash_mode?: boolean;
    task_dash_dates?: Array<ActionTaskDate>;
    task_type_filter?: number | null;
    org_id?: number;
    family_id?: number;
    type?: ActionItemGroupType | null;
    include_meetings?: boolean;
    only_mine?: boolean;
    start_date?: string;
    end_date?: string;
    search?: string;
}

export default class ActionItemsRepository extends AbstractRepository<ActionItemsResults> {
    readonly endpoint = 'action-items';

    async getActionItems(queryParams: ActionItemsParams | null = null, pagination: ApiPagination | null = null, sort: ActionItemsSortParameter | null = null): Promise<ActionItemsResults> {
        const params = {};
        // Merge all the params together so we get types on the parameters first
        Object.assign(params, pagination, queryParams, sort);
        const response = await this.client.get<ActionItemsResults>(this.endpoint, {
            params: params,
            paramsSerializer: params1 => { return qs.stringify(params1); }
        });

        return response.data;
    }
}
