





















import { Component, Vue } from 'vue-property-decorator';
import { getModule } from 'vuex-module-decorators';
import { AuthStore } from '@/store/auth-store';
import { UserPermissionsStore } from '@/staff/store/user-permissions-store';
import CorporateStaffDashboard from '@/dashboards/views/CorporateStaffDashboard.vue';
import EnrollmentCommandCenterDashboard from '@/dashboards/components/EnrollmentCommandCenterDashboard.vue';
import store from '@/store';
import { FeaturesStore } from '@/features/features-store';
import { FeatureConstants } from '@/features/feature-constants';
import { NavigationGuardNext, Route } from 'vue-router';
import { AppStateStore } from '@/store/app-state-store';
import LocationDashboard from '@/dashboards/views/LocationDashboard.vue';
import CorporateStaffDashboardMP from '@/dashboards/views/CorporateStaffDashboardMP.vue';
import { PermissionName } from '@/staff/models/user-permission-models';
import { StaffUtils } from '@/staff/staff-utils';
import { actionDashboardType, customDashboardType, enrollmentDashboardType } from '@/dashboards/models/custom-dashboards';
import { CustomDashboardService } from '@/dashboards/custom-dashboard-service';
import { CustomDashboardsStore } from '@/dashboards/store/custom-dashboards-store';
import CustomDashboardView from '@/dashboards/components/custom/CustomDashboardView.vue';

const appState = getModule(AppStateStore, store);
const userPermissionsState = getModule(UserPermissionsStore);
const authState = getModule(AuthStore, store);
const featureStore = getModule(FeaturesStore);
const staffUtils = new StaffUtils();
const customDashService = new CustomDashboardService();
const customDashState = getModule(CustomDashboardsStore);

Component.registerHooks([
    'beforeRouteLeave'
]);

@Component({
    components: {
        CustomDashboardView,
        CorporateStaffDashboardMP,
        LocationDashboard,
        CorporateStaffDashboard,
        EnrollmentCommandCenterDashboard
    }
})
export default class Home extends Vue {
    async beforeRouteLeave(to: Route, from: Route, next: NavigationGuardNext) {
        if (this.displayEccDashboard) {
            appState.updateCurrentCenterEt(null);
        }
        appState.setIsDashboard(false);
        next();
    }

    // Will be re-used in a future item when we decide to change it back
    private isCenterStaff = authState.isCenterStaff;
    private isLoaded = false;
    private hasCustomDashboards = false;

    get defaultDash() {
        return customDashService.getDefaultDash(customDashState.stored);
    }

    get defaultDashboardType() {
        return this.defaultDash?.type;
    }

    private get displayEccDashboard(): boolean {
        if (this.hasCustomDashboards) {
            return this.defaultDashboardType === enrollmentDashboardType;
        }
        return featureStore.isFeatureEnabled(FeatureConstants.ENROLLMENT_CENTER) && authState.userInfoObject!.is_on_enrollment_team && !authState.userInfoObject!.is_superuser;
    }

    private get displayCustomDash() {
        return this.defaultDashboardType === customDashboardType;
    }

    private get displayActionDash() {
        if (this.hasCustomDashboards) {
            return this.defaultDashboardType === actionDashboardType;
        }
        return this.isCenterStaff;
    }

    private get isLineLeaderEnrollEnabled(): boolean {
        return featureStore.isFeatureEnabled(FeatureConstants.LINE_LEADER_ENROLL);
    }

    async created() {
        appState.setIsDashboard(true);
        const userInfo = authState.userInfoObject;
        const featuresPromise = featureStore.init();
        await featuresPromise;
        this.hasCustomDashboards =
            featureStore.isFeatureEnabled(FeatureConstants.CRM_PLUS_MODE) &&
            await staffUtils.getUserPermission(PermissionName.CustomDashboards) &&
            featureStore.isFeatureEnabled(FeatureConstants.CUSTOM_DASHBOARDS);
        if (this.hasCustomDashboards) {
            await customDashState.init();
        }
        if (userInfo) {
            const userPermissionsPromise = userPermissionsState.init(userInfo.id);
            await userPermissionsPromise;
        }
        this.isLoaded = true;
    }
}
