import { EmailsRepository } from '@/communications/messages/repositories/emails-repository';
import { FacebookMessagesRepository } from '@/communications/messages/repositories/facebook-messages-repository';
import { TextsRepository } from '@/communications/messages/repositories/texts-repository';
import { RecordingsRepository } from '@/communications/recordings/repositories/recordings-repository';
import { AvatarValues, getAvatarBackgroundFromUser } from '@/core/avatar-utils';
import {
    ActionItem,
    ActionItemGroupType,
    ActionItemType,
    BulkActionsTypes,
    BulkDisplayItem
} from '@/dashboards/models/action-items-models';
import { PendingLeadsRepository } from '@/families/repositories/lead/pending-leads-repository';
import { PermissionName } from '@/staff/models/user-permission-models';
import { StaffUtils } from '@/staff/staff-utils';
import { TaskGroups } from '@/tasks/models/task-models';
import { TasksRepository } from '@/tasks/repositories/tasks-repository';
import { GroupedType } from '@/tasks/services/task-groups-service';
import { TaskUtilities } from '@/tasks/task-utils';
import pluralize from 'pluralize';

const pendingRepo = new PendingLeadsRepository();
const emailsRepo = new EmailsRepository();
const textsRepo = new TextsRepository();
const callsRepo = new RecordingsRepository();
const fbRepo = new FacebookMessagesRepository();
const tasksRepo = new TasksRepository();

export class ActionItemUtils {
    private staffUtils = new StaffUtils();
    private taskUtil = new TaskUtilities();

    async doBulkActions(selectedItems: Array<BulkDisplayItem>, actionType: BulkActionsTypes, taskResultId: number | null = null) {
        const callIds = [];
        const textIds = [];
        const emailIds = [];
        const fbIds = [];
        const acceptedPendingIds = [];
        const rejectedPendingIds = [];
        const completedTaskIds = [];
        const cancelledTaskIds = [];
        switch (actionType) {
            case BulkActionsTypes.ACCEPT_PENDING:
                acceptedPendingIds.push(...selectedItems.map(item => item.item_id));
                break;
            case BulkActionsTypes.REJECT_PENDING:
                rejectedPendingIds.push(...selectedItems.map(item => item.item_id));
                break;
            case BulkActionsTypes.MARK_AS_READ_COMM:
                for (const item of selectedItems) {
                    switch (item.item_type) {
                        case ActionItemType.EMAIL:
                            emailIds.push(item.item_id);
                            break;
                        case ActionItemType.TEXT:
                            textIds.push(item.item_id);
                            break;
                        case ActionItemType.FACEBOOK:
                            fbIds.push(item.item_id);
                            break;
                        case ActionItemType.CALL:
                            callIds.push(item.item_id);
                            break;
                    }
                }
                break;
            case BulkActionsTypes.BULK_COMPLETE_ITEMS:
            case BulkActionsTypes.PAST_DUE_MEETINGS:
            case BulkActionsTypes.PAST_DUE_TOURS:
                completedTaskIds.push(...selectedItems.map(item => item.item_id));
                break;
            case BulkActionsTypes.BULK_CANCEL_ITEMS:
                cancelledTaskIds.push(...selectedItems.map(item => item.item_id));
                break;
        }
        const promises: Array<Promise<any>> = [];
        if (acceptedPendingIds.length) {
            promises.push(pendingRepo.bulkAccept(acceptedPendingIds));
        }
        if (rejectedPendingIds.length) {
            promises.push(pendingRepo.bulkReject(rejectedPendingIds));
        }
        if (emailIds.length) {
            promises.push(emailsRepo.bulkMarkRead(emailIds));
        }
        if (textIds.length) {
            promises.push(textsRepo.bulkMarkReadByFamilies(textIds));
        }
        if (fbIds.length) {
            promises.push(fbRepo.bulkMarkReadByFamilies(fbIds));
        }
        if (callIds.length) {
            promises.push(callsRepo.bulkMarkRead(callIds));
        }
        if (completedTaskIds.length && taskResultId) {
            promises.push(tasksRepo.bulkComplete(completedTaskIds, taskResultId));
        }
        if (cancelledTaskIds.length) {
            promises.push(tasksRepo.bulkCancel(cancelledTaskIds));
        }
        await Promise.all(promises);
    }

    getAvatar(item: ActionItem): AvatarValues | null {
        if (item.assignee_id) {
            const initials = item.assignee_first_name.substring(0, 1).toUpperCase() + item.assignee_last_name.substring(0, 1).toUpperCase();
            const bgColor = getAvatarBackgroundFromUser(item.assignee_id);
            return { initials, bgColor };
        }
        return null;
    }

    getBulkActionsApplyButton(actionType: BulkActionsTypes): string {
        switch (actionType) {
            case BulkActionsTypes.MARK_AS_READ_COMM:
                return 'Next';
            case BulkActionsTypes.ACCEPT_PENDING:
                return 'Accept';
            case BulkActionsTypes.REJECT_PENDING:
                return 'Reject';
            case BulkActionsTypes.BULK_COMPLETE_ITEMS:
            case BulkActionsTypes.PAST_DUE_MEETINGS:
            case BulkActionsTypes.PAST_DUE_TOURS:
                return 'Complete Items';
            case BulkActionsTypes.BULK_CANCEL_ITEMS:
                return 'Cancel Items';
        }
    }

    getBulkActionGroupType(actiontype: BulkActionsTypes): ActionItemGroupType | null {
        if (actiontype === BulkActionsTypes.ACCEPT_PENDING || actiontype === BulkActionsTypes.REJECT_PENDING) {
            return ActionItemGroupType.PENDING;
        }
        if (actiontype === BulkActionsTypes.MARK_AS_READ_COMM) {
            return ActionItemGroupType.COMM;
        }
        return null;
    }

    getBulkActionsTitle(actionType: BulkActionsTypes): string {
        switch (actionType) {
            case BulkActionsTypes.MARK_AS_READ_COMM:
                return 'BULK UPDATE COMMUNICATIONS';
            case BulkActionsTypes.ACCEPT_PENDING:
                return 'BULK ACCEPT PENDING FAMILIES';
            case BulkActionsTypes.REJECT_PENDING:
                return 'BULK REJECT PENDING FAMILIES';
            case BulkActionsTypes.BULK_COMPLETE_ITEMS:
                return 'BULK COMPLETE ITEMS';
            case BulkActionsTypes.BULK_CANCEL_ITEMS:
                return 'BULK CANCEL ITEMS';
            case BulkActionsTypes.PAST_DUE_MEETINGS:
                return 'BULK COMPLETE PAST DUE MEETINGS';
            case BulkActionsTypes.PAST_DUE_TOURS:
                return 'BULK COMPLETE PAST DUE TOURS';
        }
    }

    async getBulkDisplayItems(items: Array<ActionItem>): Promise<Array<BulkDisplayItem>> {
        const bulkDisplayItems: Array<BulkDisplayItem> = [];
        for (const item of items) {
            const icon = await this.getIcon(item);
            bulkDisplayItems.push({
                assignee: item.assignee_first_name + ' ' + item.assignee_last_name,
                avatar: this.getAvatar(item),
                description: this.getDescription(item),
                guardian: item.guardian_first_name + ' ' + item.guardian_last_name,
                item_id: item.item_id,
                item_key: item.item_type + '-' + item.item_id,
                item_type: item.item_type,
                location: item.location_name,
                time: item.date_time,
                type: item.display_type,
                type_icon: icon
            });
        }
        return bulkDisplayItems;
    }

    /**
     * Get the mine only prop for bulk task/meeting actions.
     *
     * @param defaultMineOnly
     */
    async getBulkTasksMineOnly(defaultMineOnly: boolean): Promise<boolean> {
        // If mine only is already set to true, the bulk tasks will also be set to mine only
        if (defaultMineOnly) {
            return true;
        }

        // If the user has permission to edit others, allow for other tasks to be shown
        return !(await this.staffUtils.getUserPermission(PermissionName.TasksEditOthers));
    }

    getBulkWarningText(selectedItems: Array<BulkDisplayItem>, actionType: BulkActionsTypes, taskType: GroupedType | null = null): string {
        const count = selectedItems.length;
        let warningText = 'Warning! You are about to ';
        const itemString = pluralize('item', count);
        const communicationString = pluralize('communication', count);
        const familyString = pluralize('family', count);

        switch (actionType) {
            case BulkActionsTypes.MARK_AS_READ_COMM:
                warningText += `mark ${count} ${communicationString} as read/listened-to. Uncheck items you do not want to mark, and then click NEXT.`;
                break;
            case BulkActionsTypes.ACCEPT_PENDING:
                warningText += `accept ${count} pending ${familyString}. Uncheck families you do not want to accept, and then click ACCEPT. NOTES: Pending families without a location cannot be selected for bulk accept`;
                break;
            case BulkActionsTypes.REJECT_PENDING:
                warningText += `reject ${count} pending ${familyString}. Uncheck families you do not want to reject, and then click REJECT.`;
                break;
            case BulkActionsTypes.BULK_CANCEL_ITEMS:
                warningText += `cancel ${count} ${itemString}. Uncheck items you do not want to cancel, and then click CANCEL ITEMS.`;
                break;
            case BulkActionsTypes.BULK_COMPLETE_ITEMS:
                if (taskType) {
                    warningText += `complete ${count} ${taskType.typeValue} ${itemString}. Uncheck items you do not want to complete, select a Result, and then click COMPLETE ITEMS.`;
                }
                break;
            case BulkActionsTypes.PAST_DUE_MEETINGS:
                warningText += `complete ${count} past due Meetings ${itemString}. Uncheck items you do not want to complete, select a Result, and then click COMPLETE ITEMS.`;
                break;
            case BulkActionsTypes.PAST_DUE_TOURS:
                warningText += `complete ${count} past due Tours ${itemString}. Uncheck items you do not want to complete, select a Result, and then click COMPLETE ITEMS.`;
                break;
        }
        return warningText;
    }

    getDescription(item: ActionItem): string | null {
        if (item.item_type === ActionItemType.TASK) {
            return item.description ? item.description : 'No task description';
        }
        if (item.item_type === ActionItemType.PENDING && item.pending_family) {
            const pendingDescriptionArray = [];
            if (item.pending_family.primary_guardian.primary_phone) {
                pendingDescriptionArray.push('Phone: ' + item.pending_family.primary_guardian.primary_phone.number);
            }
            if (item.pending_family.primary_guardian.email) {
                pendingDescriptionArray.push('Email: ' + item.pending_family.primary_guardian.email);
            }
            if (item.pending_family.comments) {
                pendingDescriptionArray.push('Comments: ' + item.pending_family.comments);
            }
            if (item.pending_family.is_transfer || item.pending_family.is_refer) {
                const prepend = item.pending_family.is_transfer ? 'Transferred from' : 'Referred from';
                pendingDescriptionArray.push(prepend + ' ' + item.pending_family.refer_transfer_center?.values.name);
            }
            return pendingDescriptionArray.join('<br>');
        }
        return null;
    }

    async getIcon(item: ActionItem): Promise<string> {
        switch (item.item_type) {
            case ActionItemType.CALL:
                return 'mdi-phone-in-talk';
            case ActionItemType.EMAIL:
                return 'mdi-email-newsletter';
            case ActionItemType.FACEBOOK:
                return 'mdi-facebook';
            case ActionItemType.TEXT:
                return 'mdi-chat-processing';
            case ActionItemType.PENDING:
                if (item.pending_family?.is_refer || item.pending_family?.is_transfer) {
                    return 'mdi-swap-horizontal';
                }
                return 'mdi-human-male-female-child';
            case ActionItemType.TASK:
                return this.taskUtil.getIconByGroupAndType(item.task_group_id ?? TaskGroups.OTHER, item.task_type_id ?? 0);
        }
    }

    isPendingComCard(type: BulkActionsTypes): boolean {
        return type === BulkActionsTypes.ACCEPT_PENDING || type === BulkActionsTypes.REJECT_PENDING || type === BulkActionsTypes.MARK_AS_READ_COMM;
    }

}
