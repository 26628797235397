





















































































































































import { Component, Mixins, Prop, Watch } from 'vue-property-decorator';
import { LocaleMixin } from '@/locales/locale-mixin';
import {
    ActionItem,
    ActionItemsSortKey,
    ActionItemsSortParameter,
    BulkActionsTypes,
    BulkDisplayItem
} from '@/dashboards/models/action-items-models';
import { ActionItemUtils } from '@/dashboards/action-item-utils';
import { DataTableHeader } from 'vuetify';
import { getModule } from 'vuex-module-decorators';
import { AuthStore } from '@/store/auth-store';
import store from '@/store';
import { LoadingStore } from '@/store/loading-store';
import { EventTypes } from '@/constants/event-type-constants';
import { AppStateStore } from '@/store/app-state-store';
import { DataTableOptions } from '@/models/datatables';
import { SortConstants } from '@/constants/sort-constants';
import { getPagination, getSortingFromOptions } from '@/core/datatables-utils';
import { ModalMixin } from '@/core/modal-mixin';
import { TextsStore } from '@/communications/messages/stores/texts-store';
import { EmailsStore } from '@/communications/messages/stores/emails-store';
import { RecordingsStore } from '@/communications/recordings/stores/recordings-store';
import { FacebookMessagesStore } from '@/communications/messages/stores/facebook-messages-store';
import { CrmBreakpointsMixin } from '@/styles/crm-breakpoints-mixin';
import { LocationDashboardStore } from '@/dashboards/store/location-dashboard-store';
import ActionItemsRepository, { ActionItemsParams } from '@/dashboards/repositories/action-items-repository';
import { GroupedType } from '@/tasks/services/task-groups-service';
import TaskResultSelect from '@/tasks/components/TaskResultSelect.vue';
import BaseClose from '@/components/base/BaseClose.vue';
import { TaskTypeCalendar } from '@/tasks/task-type-constants';

const actionItemsUtils = new ActionItemUtils();
const appState = getModule(AppStateStore);
const authState = getModule(AuthStore, store);
const loadingState = getModule(LoadingStore);
const textsStore = getModule(TextsStore);
const facebookMessagesStore = getModule(FacebookMessagesStore);
const emailsStore = getModule(EmailsStore);
const recordingsStore = getModule(RecordingsStore);
const dashboardState = getModule(LocationDashboardStore);
const actionItemsRepo = new ActionItemsRepository();

@Component({
    components: {
        BaseClose,
        TaskResultSelect
    }
})
export default class LocationDashboardBulkActionsModal extends Mixins(LocaleMixin, ModalMixin, CrmBreakpointsMixin) {
    @Prop() isCenter!: boolean;
    @Prop({ default: null }) readonly actionType!: BulkActionsTypes;
    @Prop() isMineOnly!: boolean;
    @Prop() includeMeetings!: boolean | null;
    @Prop({ default: null }) taskTypeForBulkComplete!: GroupedType | null;
    @Prop({ default: null }) readonly calendarTasks!: ActionItem[];
    @Prop({ default: null }) readonly locationId!: number;
    private items: Array<ActionItem> = [];
    private itemsCount = 0;
    private loadingKey = LocationDashboardBulkActionsModal.name;
    private localItems: Array<BulkDisplayItem> = [];
    private selectedItems: Array<BulkDisplayItem> = [];
    private defaultSorting: ActionItemsSortParameter = {
        sort_keys: [ActionItemsSortKey.DATETIME],
        sort_dir: [SortConstants.ASCENDING]
    };

    private tableOptions: DataTableOptions = {
        itemsPerPage: 10,
        page: 1,
        sortBy: ['datetime'],
        sortDesc: [false]
    };

    private isLoaded = false;
    private needRefresh = false;
    private selectAllItems = false;
    private taskGroupId = 0;
    private taskResultId: number | null = null;

    get isAccepting() {
        return this.actionType === BulkActionsTypes.ACCEPT_PENDING;
    }

    get assigneeWidth() {
        switch (this.crmBreakpoint) {
            case 'sm':
                return this.isCenter ? '22ch' : '18ch';
            case 'md':
                return this.isCenter ? '28ch' : '20ch';
            case 'lg':
                return this.isCenter ? '34ch' : '22ch';
            case 'xl':
                return '24ch';
        }
    }

    get currentOrg() {
        return appState.storedCurrentOrg?.id ?? 0;
    }

    get cardTitle() {
        return actionItemsUtils.getBulkActionsTitle(this.actionType);
    }

    get dates() {
        return dashboardState.taskDates;
    }

    get disableApplyButton() {
        if (this.actionType === BulkActionsTypes.BULK_COMPLETE_ITEMS || this.actionType === BulkActionsTypes.PAST_DUE_MEETINGS || this.actionType === BulkActionsTypes.PAST_DUE_TOURS) {
            return this.selectedItems.length === 0 || !this.taskResultId;
        }
        return this.selectedItems.length === 0;
    }

    get buttonText() {
        return actionItemsUtils.getBulkActionsApplyButton(this.actionType);
    }

    get guardianWidth() {
        switch (this.crmBreakpoint) {
            case 'sm':
                return this.isCenter ? '22ch' : '18ch';
            case 'md':
                return this.isCenter ? '28ch' : '20ch';
            case 'lg':
                return this.isCenter ? '34ch' : '22ch';
            case 'xl':
                return '24ch';
        }
    }

    get headers(): Array<DataTableHeader> {
        const headers: Array<DataTableHeader> = [
            {
                text: '',
                value: 'data-table-select',
                width: '24px'
            },
            {
                text: 'Guardian Name',
                value: 'guardian',
                class: 'guardian',
                width: this.guardianWidth
            },
            {
                text: 'Type',
                value: 'type',
                class: 'type',
                width: this.typeWidth
            },
            {
                text: 'Assignee',
                value: 'assignee',
                class: 'assignee',
                width: this.assigneeWidth
            }
        ];
        if (!this.isCenter) {
            headers.push({
                text: 'Location',
                value: 'location',
                class: 'location',
                width: this.locationWidth
            });
        }
        headers.push(
            {
                text: this.timeHeaderText,
                value: 'datetime',
                class: 'time',
                width: this.timeWidth
            });
        return headers;
    }

    get timeHeaderText() {
        return (this.actionType === BulkActionsTypes.PAST_DUE_TOURS || this.actionType === BulkActionsTypes.PAST_DUE_MEETINGS) ? 'Due' : 'Time';
    }

    get locationWidth() {
        switch (this.crmBreakpoint) {
            case 'sm':
                return '20ch';
            case 'md':
                return '22ch';
            case 'lg':
                return '24ch';
            case 'xl':
                return '26ch';
        }
    }

    get noResultsText() {
        if (this.actionType === BulkActionsTypes.BULK_COMPLETE_ITEMS) {
            return 'No items of this type were found.';
        }
        return 'No items found.';
    }

    get showResultSelect() {
        return this.actionType === BulkActionsTypes.BULK_COMPLETE_ITEMS || this.actionType === BulkActionsTypes.PAST_DUE_TOURS || this.actionType === BulkActionsTypes.PAST_DUE_MEETINGS;
    }

    get taskTypeFilterStore() {
        return dashboardState.taskTypeFilter;
    }

    get timezone() {
        return authState.userTimeZone;
    }

    get timeWidth() {
        switch (this.crmBreakpoint) {
            case 'sm':
                return '16ch';
            case 'md':
                return '18ch';
            case 'lg':
                return '20ch';
            case 'xl':
                return '22ch';
        }
    }

    get typeWidth() {
        switch (this.crmBreakpoint) {
            case 'sm':
                return this.isCenter ? '22ch' : '14ch';
            case 'md':
                return this.isCenter ? '28ch' : '16ch';
            case 'lg':
                return this.isCenter ? '34ch' : '18ch';
            case 'xl':
                return '20ch';
        }
    }

    get warningText() {
        return actionItemsUtils.getBulkWarningText(this.selectedItems, this.actionType, this.taskTypeForBulkComplete);
    }

    @Watch('tableOptions', { deep: true })
    async paginationChanged() {
        if (this.isLoaded) {
            await this.loadData();
        }
    }

    @Watch('modelValue')
    async modalOpened() {
        if (this.modelValue) {
            this.resetValues();
            this.isLoaded = false;
            await this.loadData();
            this.isLoaded = true;
        }
        if (document.activeElement) {
            (document.activeElement as any).blur();
        }
    }

    closeModal() {
        if (this.needRefresh) {
            this.$emit(EventTypes.UPDATED);
        }
        this.close();
    }

    async loadData() {
        loadingState.loadingIncrement(this.loadingKey);
        const pagination = getPagination(this.tableOptions);
        const sort = getSortingFromOptions(this.tableOptions, this.defaultSorting);
        const params = this.setupCorrectParams(this.actionType);
        const results = await actionItemsRepo.getActionItems(params, pagination, sort);
        this.items = results.items;
        this.itemsCount = results.counts.items ?? 0;
        this.localItems = await actionItemsUtils.getBulkDisplayItems(this.items);
        this.selectedItems = [...this.localItems];
        if (this.isAccepting) {
            this.selectedItems = this.selectedItems.filter(item => !!item.location);
        }
        loadingState.loadingDecrement(this.loadingKey);
    }

    async updateInboxCount() {
        const inboxPromises = [];
        inboxPromises.push(textsStore.retrieveTextsInboxCount({ org_id: this.currentOrg }));
        inboxPromises.push(emailsStore.retrieveEmailsInboxCountForOrg({ org_id: this.currentOrg }));
        inboxPromises.push(facebookMessagesStore.retrieveFacebookMessagesInboxCount({ org_id: this.currentOrg }));
        inboxPromises.push(recordingsStore.retrieveRecordingsInboxCount(this.currentOrg));

        await Promise.all(inboxPromises);
    }

    async doBulkActions() {
        loadingState.loadingIncrement(this.loadingKey);
        try {
            await actionItemsUtils.doBulkActions(this.selectedItems, this.actionType, this.taskResultId);
            if (this.actionType === BulkActionsTypes.MARK_AS_READ_COMM) {
                // The change maybe too big so I felt like this maybe the most accurate way to update the inbox count
                await this.updateInboxCount();
            }
            await this.loadData();
        } catch (e) {
            // Do nothing
        }
        loadingState.loadingDecrement(this.loadingKey);
        this.needRefresh = true;
        if (!this.itemsCount) {
            this.closeModal();
        }
    }

    resetValues() {
        this.items = [];
        this.itemsCount = 0;
        this.localItems = [];
        this.selectedItems = [];
        this.defaultSorting = {
            sort_keys: [ActionItemsSortKey.DATETIME],
            sort_dir: [SortConstants.ASCENDING]
        };
        this.tableOptions = {
            itemsPerPage: 10,
            page: 1,
            sortBy: ['datetime'],
            sortDesc: [false]
        };
        this.selectAllItems = false;
        this.taskResultId = null;
        this.needRefresh = false;
    }

    private selectAll() {
        this.selectedItems = [];
        if (this.selectAllItems) {
            this.selectAllItems = false;
            return;
        }
        this.localItems.forEach(item => {
           if (item.location || !this.isAccepting) {
               this.selectedItems.push(item);
           }
        });
        this.selectAllItems = true;
    }

    setupCorrectParams(actionType: BulkActionsTypes): ActionItemsParams {
        const itemsParams: ActionItemsParams = {
            org_id: this.locationId ?? this.currentOrg,
            only_mine: this.isMineOnly
        };
        if (actionItemsUtils.isPendingComCard(actionType)) {
            itemsParams.non_tasks_dash_mode = true;
            const groupType = actionItemsUtils.getBulkActionGroupType(actionType);

            // Group type in bulk actions modal cannot be null
            if (groupType) {
                itemsParams.type = groupType;
            }
        } else {
            itemsParams.tasks_dash_mode = true;
            itemsParams.task_dash_dates = this.dates;
            if (this.includeMeetings) {
                itemsParams.include_meetings = true;
            }

            // Bulk Cancel items respects task type filter select from the dashboard table, but Bulk Complete ignores that
            if (actionType === BulkActionsTypes.BULK_CANCEL_ITEMS) {
                if (this.taskTypeFilterStore) {
                    itemsParams.task_type_filter = this.taskTypeFilterStore;
                }
            }

            // Bulk Complete items will get the task type from the small wizard appear before showing this modal
            if (actionType === BulkActionsTypes.BULK_COMPLETE_ITEMS) {
                if (this.taskTypeForBulkComplete && this.taskTypeForBulkComplete.typeId && this.taskTypeForBulkComplete.groupId) {
                    this.taskGroupId = this.taskTypeForBulkComplete.groupId;
                    itemsParams.task_type_filter = this.taskTypeForBulkComplete.typeId;
                }
            }

            if (actionType === BulkActionsTypes.PAST_DUE_MEETINGS || actionType === BulkActionsTypes.PAST_DUE_TOURS) {
                if (this.calendarTasks) {
                    const meeting = this.calendarTasks.find(calendar => calendar.task_type_id === TaskTypeCalendar.MEETING);
                    const tour = this.calendarTasks.find(event => event.task_type_id === TaskTypeCalendar.TOUR);
                    itemsParams.task_type_filter = meeting && actionType === BulkActionsTypes.PAST_DUE_MEETINGS ? TaskTypeCalendar.MEETING : TaskTypeCalendar.TOUR;

                    const groupID = meeting && actionType === BulkActionsTypes.PAST_DUE_MEETINGS ? meeting?.task_group_id : tour?.task_group_id;
                    this.taskGroupId = groupID ?? 0;
                }
                itemsParams.non_tasks_dash_mode = false;
                itemsParams.tasks_dash_mode = true;
                itemsParams.include_meetings = true;
                itemsParams.task_dash_dates = ['past'];
            }
        }
        return itemsParams;
    }

    private setTaskResult(result: number | null) {
        this.taskResultId = result;
    }

    timeFormat(time: string) {
        return this.formatShortDateTimeWithTimezone(time, this.timezone);
    }
}
