import { OrgsStore } from '@/store/orgs-store';
import cloneDeep from 'lodash/cloneDeep';
import { Org, OrgTreeNode } from '@/models/organization/org';
import { Brand } from '@/organizations/brands/models/brand-models';
import { CrmTypeOption } from '@/crm-types/models/crm-type';
import { getModule } from 'vuex-module-decorators';

export interface LocationOption {
    type: 'org' | 'brand' | 'locationGroup';
    name: string;
    id: number;
    key: string;
}

export class OrgsUtil {
    private orgsStore: OrgsStore;

    public constructor() {
        this.orgsStore = getModule(OrgsStore);
    }

    /**
     * Determine if the given org id is outside the hierarchy of the current user's org and below.
     *
     * @param orgId
     */
    public async canUserAccessOrg(orgId: number) {
        if (!this.orgsStore.mappedEntities || this.orgsStore.mappedEntities.size < 1) {
            await this.orgsStore.init();
        }

        // The org store by default only returns orgs that are at or below the user's level in the hierarchy
        return this.orgsStore.mappedEntities.has(orgId);
    }

    /**
     * Get the select list options when selecting an org, brand, or location group.
     *
     * @param orgs
     * @param brands
     * @param locationGroups
     */
    public getLocationOptions(orgs: Array<Org>, brands: Array<Brand>, locationGroups: Array<CrmTypeOption>): Array<LocationOption> {
        let options: Array<LocationOption> = orgs.map((org) => {
            return {
                type: 'org',
                name: org.name,
                id: org.id,
                key: 'org-' + org.id
            };
        });
        options = options.concat(brands.map((brand) => {
            return {
                type: 'brand',
                name: 'Brand: ' + brand.name,
                id: brand.id,
                key: 'brand-' + brand.id
            };
        }));
        options = options.concat(locationGroups.map((group) => {
            return {
                type: 'locationGroup',
                name: 'Location Group: ' + group.value,
                id: group.id,
                key: 'locationGroup-' + group.id
            };
        }));
        return options;
    }

    /**
     * Sort the orgs according to the hierarchy level.
     *
     * @param orgs
     */
    public sortOrgsByHierarchy(orgs: Array<Org>): Array<Org> {
        const sorted = cloneDeep(orgs);
        return sorted.sort((a: Org, b: Org) => {
            // make sure root org is always first
            if (a.id === 1) {
                return -1;
            }
            if (b.id === 1) {
                return 1;
            }
            // break level ties by name
            if (a.level_order === b.level_order) {
                return a.name.localeCompare(b.name);
            }
            return a.level_order < b.level_order ? -1 : 1;
        });
    }

    /**
     * Sort the orgs by name.
     *
     * @param orgs
     */
    public sortOrgsByName(orgs: Array<Org>): Array<Org> {
        return orgs.sort((a, b) => a.name.toLocaleLowerCase().localeCompare(b.name.toLocaleLowerCase()));
    }

    /**
     * Get org with lowest id
     *
     * @param orgs
     */
    public getOrgWithLowestId(orgs: Array<Org>): Org {
        return orgs.reduce((acc, org) => org.id < acc.id ? org : acc, orgs[0]);
    }

    /**
     * Extract the org centers from the org tree.
     *
     * @param entities
     * @param orgTree
     */
    public static extractOrgCentersFromTree(entities: Array<Org>, orgTree: OrgTreeNode): Array<Org> {
        let orgs: Array<Org> = [];

        const foundOrg = entities.find(org => org.id === orgTree.id && org.center !== null);
        if (foundOrg) {
            orgs.push(orgTree);
        }

        if (orgTree.children && orgTree.children.length > 0) {
            orgTree.children.forEach((node) => {
                orgs = [...orgs, ...this.extractOrgCentersFromTree(entities, node)];
            });
        }
        return orgs;
    }
}
