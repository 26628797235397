






































































































































































import { ActionItemUtils } from '@/dashboards/action-item-utils';
import { Component, Mixins, Prop, PropSync, Watch } from 'vue-property-decorator';
import { LocaleMixin } from '@/locales/locale-mixin';
import {
    ActionItem,
    ActionItemResultCounts,
    ActionItemsSortKey,
    ActionItemsSortParameter,
    ActionTaskDate,
    BulkActionsTypes
} from '@/dashboards/models/action-items-models';
import ActionItemsRepository, { ActionItemsParams } from '@/dashboards/repositories/action-items-repository';
import { LoadingStore } from '@/store/loading-store';
import { getModule } from 'vuex-module-decorators';
import { AppStateStore } from '@/store/app-state-store';
import LocationDashboardToDo from '@/dashboards/components/LocationDashboardTabs/LocationDashboardToDo.vue';
import { LocationDashboardStore } from '@/dashboards/store/location-dashboard-store';
import { DataTableOptions } from '@/models/datatables';
import { getPagination, getSortingFromOptions } from '@/core/datatables-utils';
import { SortConstants } from '@/constants/sort-constants';
import { EventTypes } from '@/constants/event-type-constants';
import { CrmBreakpointsMixin } from '@/styles/crm-breakpoints-mixin';
import TaskGroupsService, { GroupedType } from '@/tasks/services/task-groups-service';
import LocationDashboardBulkActionsModal
    from '@/dashboards/components/LocationDashboardTabs/LocationDashboardBulkActionsModal.vue';
import LocationDashboardBulkActionsButton
    from '@/dashboards/components/LocationDashboardTabs/LocationDashboardBulkActionsButton.vue';
import BulkCompleteTasksTypeSelect from '@/dashboards/components/LocationDashboardTabs/BulkCompleteTasksTypeSelect.vue';

const actionItemsRepo = new ActionItemsRepository();
const actionItemUtils = new ActionItemUtils();
const appState = getModule(AppStateStore);
const dashboardState = getModule(LocationDashboardStore);
const loadingState = getModule(LoadingStore);
const taskGroupsService = new TaskGroupsService();

@Component({
    components: { LocationDashboardToDo, LocationDashboardBulkActionsModal, LocationDashboardBulkActionsButton, BulkCompleteTasksTypeSelect }
})
export default class TaskMeetingToDos extends Mixins(LocaleMixin, CrmBreakpointsMixin) {
    @Prop() isCenter!: boolean;
    @Prop() isMineOnly!: boolean;
    @Prop() includeMeetings!: boolean | null;
    @PropSync('expanded') expandedSync!: boolean;

    private customNoResults: string | null = null;
    /**
     * The isMineOnly prop to apply to the bulk actions modal.
     * If the user doesn't have permission to edit other's tasks, only show their own.
     */
    private isMineOnlyBulk = false;
    private items: Array<ActionItem> = [];
    private loadingKey = 'taskMeetingToDos';
    private loaded = false;
    private taskTypes: Array<GroupedType> = [];
    private updatedEvent = EventTypes.UPDATED;

    private search = '';
    private actualSearch = '';

    private defaultSorting: ActionItemsSortParameter = {
        sort_keys: [ActionItemsSortKey.DATETIME],
        sort_dir: [SortConstants.ASCENDING]
    };

    private actionType: BulkActionsTypes | null = null;
    private resultsCount: ActionItemResultCounts = {
        items: 0,
        future: 0,
        past: 0,
        today: 0,
        total: 0
    }

    private showBulkActionsModal = false;
    private showBulkCompleteTasksTypeSelectModal = false;
    private typeSelectedForBulkComplete: GroupedType | null = null;

    get currentOrg() {
        return appState.storedCurrentOrg?.id ?? 0;
    }

    get dates() {
        return dashboardState.taskDates;
    }

    set dates(dates: Array<ActionTaskDate>) {
        dashboardState.setTaskDates(dates);
    }

    get futureCount() {
        return this.resultsCount.future ?? 0;
    }

    get isSearching() {
        return !!this.actualSearch;
    }

    get itemsCount() {
        return Number(this.resultsCount.items ?? 0);
    }

    get pastCount() {
        return this.resultsCount.past ?? 0;
    }

    get multiSelect() {
        return dashboardState.taskMultiSelect;
    }

    set multiSelect(flag: boolean) {
        dashboardState.setTaskMultiSelect(flag);
    }

    get tableOptions() {
        return dashboardState.tasksPagination;
    }

    set tableOptions(options: DataTableOptions) {
        dashboardState.setTasksPagination(options);
    }

    get taskTypeFilter() {
        return dashboardState.taskTypeFilter;
    }

    set taskTypeFilter(filter: number | null) {
        dashboardState.setTaskTypeFilter(filter);
    }

    get title() {
        return this.includeMeetings ? 'Tasks, Tours & Meetings' : 'Tasks';
    }

    get total() {
        // can't directly use itemsCount because of searching
        let count = 0;
        if (this.hasDate('past')) {
            count += this.pastCount;
        }
        if (this.hasDate('today')) {
            count += this.todayCount;
        }
        if (this.hasDate('future')) {
            count += this.futureCount;
        }
        return count;
    }

    get isTaskTablePrompt() {
        return !this.todayCount && !this.isSearching && (this.dates.includes('today'));
    };

    get tablePrompt() {
        if (!this.loaded) {
            return 'Loading table...';
        }

        if (!this.pastCount) {
            return 'CONGRATULATIONS! All current and past due items are COMPLETE--pat yourself on the back for a job well done!!';
        } else {
            return 'GREAT JOB! All of today’s items are complete!! You have some past due items; consider going through them, completing the ones that are still worth doing and canceling the ones that are too old.';
        }
    }

    get todayCount() {
        return this.resultsCount.today ?? 0;
    }

    get grandTotal() {
        return this.pastCount + this.todayCount + this.futureCount;
    }

    get itemCountMoreThanTen() {
        return this.grandTotal > 10;
    }

    @Watch('actualSearch')
    async searchChanged() {
        await this.loadData();
    }

    @Watch('currentOrg', { immediate: true })
    async orgChanged() {
        await this.loadData();
    }

    @Watch('dates')
    async datesChanged() {
        await this.loadData();
        if (this.total > 0) {
            this.expandedSync = true;
        }
    }

    @Watch('includeMeetings')
    private async includeMeetingsChanged() {
        this.setTaskTypes();
        await this.loadData();
    }

    @Watch('isMineOnly')
    async mineOnlyChanged() {
        await this.loadData();
        this.isMineOnlyBulk = await actionItemUtils.getBulkTasksMineOnly(this.isMineOnly);
    }

    @Watch('multiSelect')
    async multiSelectChanged() {
        if (!this.multiSelect) {
           this.dates = ['today'];
        }
    }

    @Watch('tableOptions', { deep: true })
    async paginationChanged() {
        await this.loadData();
    }

    @Watch('taskTypeFilter', { deep: true })
    async taskTypeFilterChanged() {
        await this.loadData();
    }

    private async created() {
        loadingState.loadingIncrement(this.loadingKey);
        await taskGroupsService.init();
        this.isMineOnlyBulk = await actionItemUtils.getBulkTasksMineOnly(this.isMineOnly);
        this.setTaskTypes();
        loadingState.loadingDecrement(this.loadingKey);
    }

    clearSearch() {
        this.search = '';
        this.actualSearch = '';
    }

    doSearch() {
        if (this.search.length >= 2) {
            this.actualSearch = this.search;
        } else {
            this.actualSearch = '';
        }
    }

    handleAction(type: BulkActionsTypes) {
        this.actionType = type;
        if (this.actionType === BulkActionsTypes.BULK_COMPLETE_ITEMS) {
            this.showBulkCompleteTasksTypeSelectModal = true;
            return;
        }
        this.showBulkActionsModal = true;
    }

    handleTypeSelect(typeSelect: number | null) {
        const result = this.taskTypes.find(item => item.typeId === typeSelect);
        if (result) {
            this.typeSelectedForBulkComplete = result;
            this.showBulkActionsModal = true;
        }
    }

    async loadData() {
        if (!this.currentOrg) {
            return;
        }
        if (this.includeMeetings === null) {
            // don't double load data when we load the flag's value
            return;
        }
        if (this.dates.length === 0 && !this.isSearching) {
            this.items = [];
            this.resultsCount.items = 0;
            this.customNoResults = 'Please select a time range.';
            return;
        }
        this.customNoResults = null;
        loadingState.loadingIncrement(this.loadingKey);
        const itemsParams: ActionItemsParams = {
            tasks_dash_mode: true,
            org_id: this.currentOrg,
            only_mine: this.isMineOnly,
            task_dash_dates: this.isSearching ? ['past', 'today', 'future'] : this.dates,
            include_meetings: this.includeMeetings,
            search: this.actualSearch
        };
        if (this.taskTypeFilter) {
            itemsParams.task_type_filter = this.taskTypeFilter;
        }
        const pagination = getPagination(this.tableOptions);
        const sort = getSortingFromOptions(this.tableOptions, this.defaultSorting);
        const resultsPromise = actionItemsRepo.getActionItems(itemsParams, pagination, sort);
        const results = await resultsPromise;
        this.items = results.items;
        // this is what's used for actual table pagination
        this.resultsCount.items = results.counts.items;
        this.resultsCount.past = results.counts.past;
        this.resultsCount.today = results.counts.today;
        this.resultsCount.future = results.counts.future;
        // make sure we're not stranded
        const maxPage = Math.ceil(this.itemsCount / this.tableOptions.itemsPerPage);
        if (this.tableOptions.page > maxPage) {
            this.tableOptions.page = Math.max(1, maxPage);
        }
        if (!this.isSearching) {
            this.emitTotal();
        }
        this.loaded = true;
        loadingState.loadingDecrement(this.loadingKey);
    }

    emitTotal() {
        this.$emit(EventTypes.COUNT, this.total);
    }

    hasDate(date: ActionTaskDate): boolean {
        return this.dates.includes(date);
    }

    /**
     * Set the task types to display in the filter by type list.
     */
    setTaskTypes() {
        this.taskTypes = taskGroupsService.getTaskGroupedTypes(this.includeMeetings ?? true);
    }

    toggleDate(date: ActionTaskDate) {
        if (this.multiSelect) {
            if (this.hasDate(date)) {
                this.dates = this.dates.filter(d => d !== date);
            } else {
                this.dates.push(date);
            }
        } else {
            this.dates = [date];
        }
    }
}
