import AutomationLayout from '@/automation/views/AutomationLayout.vue';
import Workflows from '@/automation/workflows/views/Workflows.vue';
import EmailAttachmentsList from '@/communications/attachments/components/EmailAttachmentsList.vue';
import FamiliesHeader from '@/families/views/FamiliesHeader.vue';
import FamiliesTable from '@/families/views/FamiliesTable.vue';
import ViewLandingPage from '@/automation/landing-pages/views/ViewLandingPage.vue';
import NewFamilyHub from '@/families/views/NewFamilyHub.vue';
import ViewGroupEmailHistoryStats from '@/reports/views/ViewGroupEmailHistoryStats.vue';
import ViewGroupTextHistoryStats from '@/reports/views/ViewGroupTextHistoryStats.vue';
import Vue from 'vue';
import VueRouter, { RawLocation, Route } from 'vue-router';
import Home from '@/views/Home.vue';
import { AuthStore } from '@/store/auth-store';
import { getModule } from 'vuex-module-decorators';
import Samples from '@/views/Samples.vue';
import Samples2 from '@/views/Samples2.vue';
import store from '@/store';
import HistoryExample from '@/views/HistoryExample.vue';
import CalendarSample from '@/views/CalendarSample.vue';
import ChartExamples from '@/views/ChartExamples.vue';
import Logout from '@/views/Logout.vue';
import LoginLayout from '@/views/login/LoginLayout.vue';
import ForgotUsername from '@/views/login/ForgotUsername.vue';
import ForgotPassword from '@/views/login/ForgotPassword.vue';
import ResetPassword from '@/views/login/ResetPassword.vue';
import ConfirmEula from '@/views/login/ConfirmEula.vue';
import SetRecoveryEmail from '@/views/login/SetRecoveryEmail.vue';
import SettingsLayout from '@/settings/views/SettingsLayout.vue';
import TourCalendar from '@/components/tasks/TourCalendar.vue';
import MessageTemplates from '@/communications/templates/views/MessageTemplates.vue';
import TextTemplateEditor from '@/communications/templates/views/TextTemplateEditor.vue';
import Inbox from '@/communications/messages/views/Inbox.vue';
import Staff from '@/staff/views/Staff.vue';
import Organization from '@/organizations/views/Organization.vue';
import Integrations from '@/integrations/views/Integrations.vue';
import LandingPages from '@/automation/landing-pages/components/LandingPages.vue';
import PstSettings from '@/automation/landing-pages/components/PstSettings.vue';
import ReportsLayout from '@/reports/views/ReportsLayout.vue';
import CustomReports from '@/reports/custom/CustomReports.vue';
import PastReports from '@/reports/components/PastReports.vue';
import SystemSettings from '@/settings/components/SystemSettings.vue';
import FeatureSettings from '@/settings/components/FeatureSettings.vue';
import TourKiosk from '@/views/TourKiosk.vue';
import StatusesSettings from '@/settings/components/StatusesSettings.vue';
import EmptyRouterLayout from '@/views/EmptyRouterLayout.vue';
import LegacyReports from '@/reports/components/LegacyReports.vue';
import GroupCommHistoryReport from '@/reports/components/group-history/GroupCommHistoryReport.vue';
import MarketingSettings from '@/automation/components/MarketingSettings.vue';
import ClientFormSettings from '@/automation/landing-pages/views/ClientFormSettings.vue';
import CorpDashboardWrapper from '@/views/CorpDashboardWrapper.vue';
import EnrollmentCommandCenterDashboard from '@/dashboards/components/EnrollmentCommandCenterDashboard.vue';
import ChamaileonEmailTemplateEditor from '@/communications/templates/views/ChamaileonEmailTemplateEditor.vue';
import SixAgentContactSearch from '@/integrations/components/SixAgentContactSearch.vue';
import DripCampaigns from '@/automation/drip-campaigns/views/DripCampaigns.vue';
import InboxCallRecordingTable from '@/communications/recordings/components/InboxCallRecordingTable.vue';
import LocationDashboard from '@/dashboards/views/LocationDashboard.vue';
import LocationDashboardToDo from '@/dashboards/components/LocationDashboardTabs/LocationDashboardToDo.vue';
import { RouteConstants, TabTitles } from '@/router/route-constants';
import NylasAuthRedirect from '@/integrations/components/NylasAuthRedirect.vue';
import LayoutWrapper from '@/views/LayoutWrapper.vue';
import LoginLLE from '@/views/login/LoginLLE.vue';
import SsoResetPassword from '@/views/login/SsoResetPassword.vue';
import SuperuserSettings from '@/settings/components/SuperuserSettings.vue';
import SsoCode from '@/auth/SsoCode.vue';
import LoginIdp from '@/views/login/LoginIdp.vue';
import CustomDashboardView from '@/dashboards/components/custom/CustomDashboardView.vue';
import VerifyEmail from '@/views/login/VerifyEmail.vue';

const authState = getModule(AuthStore, store);

Vue.use(VueRouter);

// Stop throwing errors in console about routes being duplicated.
// Stolen from: https://stackoverflow.com/a/67102038/344028
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location: RawLocation): Promise<Route> {
    return new Promise((resolve, reject) => {
        originalPush.call(this, location, () => {
            // On complete.
            resolve(this.currentRoute);
        }, (error) => {
            // On abort, ignore NavigationDuplicated error.
            if (error.name === 'NavigationDuplicated') {
                resolve(this.currentRoute);
            } else {
                reject(error);
            }
        });
    });
};

/**
 * Get the routes for the app.
 * Change contents in LayoutTabContent as well.
 *
 * @param mainContentOnly Whether to get the routing for only the content within the <v-main> block
 */
export function getRoutes(mainContentOnly = false) {
    const prefix = mainContentOnly ? '/main/' : '';

    const mainContent = [
        {
            path: prefix + 'dashboard',
            name: 'home',
            component: Home,
            meta: {
                tabTitle: prefix + ' Dashboard'
            }
        },
        {
            path: prefix + 'dashboard-custom/:dashId',
            name: 'dashboard-custom',
            component: CustomDashboardView,
            props: (route: Route) => {
                const dashId = Number.parseInt(route.params.dashId);
                if (Number.isNaN(dashId)) {
                    return 0;
                }

                return { dashId };
            }
        },
        {
            path: prefix + 'dashboard-corp',
            name: 'dashboard-corp',
            component: CorpDashboardWrapper,
            meta: {
                tabTitle: TabTitles.CORPORATE_DASHBOARD
            }
        },
        {
            path: prefix + 'dashboard-et',
            name: 'dashboard-et',
            component: EnrollmentCommandCenterDashboard,
            meta: {
                tabTitle: TabTitles.ENROLLMENT_DASHBOARD
            },
            props: (route: Route) => {
                const inContactMode = Boolean(route.params.inContactMode);
                const openAddFamily = Boolean(route.params.openAddFamily);
                const leadName = route.params.leadName;
                const schoolNum = route.params.schoolNum;
                return { inContactMode, leadName, openAddFamily, schoolNum };
            }
        },
        {
            path: prefix + RouteConstants.DASHBOARD_LOCATION_MAIN,
            name: RouteConstants.DASHBOARD_LOCATION_MAIN,
            component: LocationDashboard,
            meta: {
                tabTitle: TabTitles.LOCATION_DASHBOARD
            },
            children: [
                {
                    path: 'actions',
                    name: RouteConstants.DASHBOARD_LOCATION_TODO,
                    component: LocationDashboardToDo
                },
                {
                    path: 'calendar',
                    name: RouteConstants.DASHBOARD_LOCATION_CALENDAR,
                    component: TourCalendar
                },
                {
                    path: 'recent',
                    name: RouteConstants.DASHBOARD_LOCATION_RECENT,
                    component: LocationDashboardToDo
                },
                {
                    path: 'starts',
                    name: RouteConstants.DASHBOARD_LOCATION_STARTS,
                    component: LocationDashboardToDo
                }
            ]
        },
        {
            path: prefix + 'inbox',
            name: 'inbox',
            component: Inbox,
            meta: {
                tabTitle: TabTitles.INBOX
            },
            children: [
                {
                    path: 'text',
                    name: 'inbox-text-messages',
                    meta: {
                        tabTitle: TabTitles.INBOX_TEXTS
                    }
                },
                {
                    path: 'email',
                    name: 'inbox-email-messages',
                    meta: {
                        tabTitle: TabTitles.INBOX_EMAILS
                    }
                },
                {
                    path: 'facebook',
                    name: 'inbox-facebook-messages',
                    meta: {
                        tabTitle: TabTitles.INBOX_FACEBOOK
                    }
                },
                {
                    path: 'recordings',
                    name: 'inbox-call-recordings',
                    component: InboxCallRecordingTable,
                    meta: {
                        tabTitle: TabTitles.INBOX_RECORDINGS
                    }
                }
            ]
        },
        {
            path: prefix + 'families',
            redirect: { name: 'families-filtered', params: { statusFilter: 'active' } },
            name: 'families',
            component: FamiliesHeader,
            meta: {
                tabTitle: TabTitles.FAMILIES
            },
            children: [
                {
                    path: 'list/:statusFilter',
                    name: 'families-filtered',
                    component: FamiliesTable,
                    props: true,
                    meta: {
                        tabTitle: TabTitles.FAMILIES
                    }
                }
            ]
        },
        {
            path: prefix + 'families/:id',
            name: RouteConstants.FAMILY_HUB,
            component: NewFamilyHub,
            props: (route: Route) => {
                const id = Number.parseInt(route.params.id);
                if (Number.isNaN(id)) {
                    return 0;
                }

                return { id };
            }
        },
        {
            path: prefix + 'six-agent-contact-search/:contactId/:ani',
            name: 'six-agent-contact-search',
            component: SixAgentContactSearch,
            props: true
        },
        {
            path: prefix + 'automation',
            name: 'automation',
            component: AutomationLayout,
            redirect: { name: 'message-templates' },
            meta: {
                tabTitle: TabTitles.WORKFLOW_AUTOMATION
            },
            children: [
                {
                    path: 'message-templates',
                    name: 'message-templates',
                    component: MessageTemplates,
                    meta: {
                        tabTitle: TabTitles.MESSAGE_TEMPLATES
                    }
                },
                {
                    path: 'reminders',
                    name: 'reminders',
                    component: MessageTemplates,
                    props: () => {
                        return { isTemplate: false };
                    },
                    meta: {
                        tabTitle: TabTitles.REMINDER_TEMPLATES
                    }
                },
                {
                    path: 'email-attachments',
                    name: 'email-attachments',
                    component: EmailAttachmentsList,
                    meta: {
                        tabTitle: TabTitles.EMAIL_ATTACHMENTS
                    }
                },
                {
                    path: 'marketplace',
                    name: 'marketplace',
                    component: Integrations,
                    meta: {
                        tabTitle: TabTitles.MARKETPLACE
                    }
                },
                {
                    path: 'landing-pages',
                    name: 'landing-pages',
                    component: LandingPages,
                    meta: {
                        tabTitle: TabTitles.LANDING_PAGES
                    }
                },
                {
                    path: 'workflows',
                    name: 'workflows-automation',
                    component: Workflows,
                    meta: {
                        tabTitle: TabTitles.WORKFLOW_AUTOMATION
                    }
                },
                {
                    path: 'drip-campaigns',
                    name: 'drip-campaigns',
                    component: DripCampaigns,
                    meta: {
                        tabTitle: TabTitles.DRIP_CAMPAIGNS
                    }
                },
                {
                    path: 'parent-scheduled-tours',
                    name: 'parent-scheduled-tours',
                    component: PstSettings,
                    meta: {
                        tabTitle: TabTitles.PARENT_SCHEDULED_TOURS
                    }
                },
                {
                    path: 'marketing',
                    name: 'marketing-settings',
                    component: MarketingSettings,
                    meta: {
                        tabTitle: TabTitles.MARKETING_SETTINGS
                    }
                }
            ]
        },
        {
            path: prefix + 'reports',
            name: 'reports',
            component: ReportsLayout,
            meta: {
                tabTitle: TabTitles.REPORTS
            },
            children: [
                {
                    path: 'standard-reports',
                    name: 'standard-reports',
                    component: CustomReports,
                    props: () => {
                        return { standardReportsMode: true };
                    },
                    meta: {
                        tabTitle: TabTitles.STANDARD_REPORTS
                    }
                },
                {
                    path: 'custom-reports',
                    name: 'custom-reports',
                    component: CustomReports,
                    props: true,
                    meta: {
                        tabTitle: TabTitles.CUSTOM_REPORTS
                    }
                },
                {
                    path: 'previously-scheduled',
                    name: 'previously-scheduled',
                    component: PastReports,
                    props: true,
                    meta: {
                        tabTitle: TabTitles.PREV_SCHEDULED_REPORTS
                    }
                },
                {
                    path: 'logs/:id',
                    name: 'report-log',
                    component: CustomReports,
                    props: (route: Route) => {
                        const id = Number.parseInt(route.params.id);
                        if (Number.isNaN(id)) {
                            return 0;
                        }

                        return { logId: id };
                    },
                    meta: {
                        tabTitle: TabTitles.CUSTOM_REPORTS
                    }
                },
                {
                    path: 'legacy-reports',
                    name: 'legacy-reports',
                    component: LegacyReports,
                    meta: {
                        tabTitle: TabTitles.LEGACY_REPORTS
                    }
                },
                {
                    path: 'group-comm-history-report',
                    name: 'group-comm-history-report',
                    component: GroupCommHistoryReport,
                    meta: {
                        tabTitle: TabTitles.GROUP_COMM_HISTORY
                    }
                }
            ]
        },
        {
            path: prefix + 'group-comm-history-report/email/:id',
            name: 'group-email',
            component: ViewGroupEmailHistoryStats,
            props: (route: Route) => {
                const id = Number.parseInt(route.params.id);
                if (Number.isNaN(id)) {
                    return 0;
                }
                return { id };
            },
            meta: {
                tabTitle: TabTitles.REPORTS
            }
        },
        {
            path: prefix + 'group-comm-history-report/text/:id',
            name: 'group-text',
            component: ViewGroupTextHistoryStats,
            props: (route: Route) => {
                const id = Number.parseInt(route.params.id);
                if (Number.isNaN(id)) {
                    return 0;
                }
                return { id };
            },
            meta: {
                tabTitle: TabTitles.REPORTS
            }
        },
        {
            path: prefix + 'landing-page/:id',
            name: 'landing-page',
            component: ViewLandingPage,
            props: (route: Route) => {
                const id = Number.parseInt(route.params.id);
                if (Number.isNaN(id)) {
                    return 0;
                }
                return { id };
            },
            meta: {
                tabTitle: TabTitles.LANDING_PAGES
            }
        },
        {
            path: prefix + 'client-form-settings',
            name: 'client-form-settings',
            component: ClientFormSettings,
            meta: {
                tabTitle: TabTitles.REPORTS
            }
        },
        {
            path: prefix + 'settings',
            name: 'settings',
            component: SettingsLayout,
            meta: {
                tabTitle: TabTitles.SETTINGS
            },
            children: [
                {
                    path: 'organization',
                    name: 'organization-settings',
                    component: Organization,
                    meta: {
                        tabTitle: TabTitles.ORGANIZATION_SETTINGS
                    }
                },
                {
                    path: 'users',
                    name: 'user-information',
                    component: Staff,
                    meta: {
                        tabTitle: TabTitles.USER_SETTINGS
                    }
                },
                {
                    path: 'system',
                    name: 'system-settings',
                    component: SystemSettings,
                    meta: {
                        tabTitle: TabTitles.SYSTEM_SETTINGS
                    },
                    children: [
                        {
                            path: 'superuser',
                            name: 'superuser-settings',
                            component: SuperuserSettings,
                            meta: {
                                tabTitle: TabTitles.FEATURE_SETTINGS
                            }
                        },
                        {
                            path: 'features',
                            name: 'features-settings',
                            component: FeatureSettings,
                            meta: {
                                tabTitle: TabTitles.FEATURE_SETTINGS
                            }
                        },
                        {
                            path: 'statuses',
                            name: 'statuses-settings',
                            component: StatusesSettings,
                            meta: {
                                tabTitle: TabTitles.STATUS_SETTINGS
                            }
                        }
                    ]
                }
            ]
        },
        {
            path: prefix + 'template-editor/email/:id?',
            name: 'template-editor-email',
            component: ChamaileonEmailTemplateEditor,
            props: true,
            meta: {
                tabTitle: TabTitles.MESSAGE_TEMPLATES
            }
        },
        {
            path: prefix + 'template-editor/email-reminder/:id?',
            name: 'template-editor-email-reminder',
            component: ChamaileonEmailTemplateEditor,
            props: true,
            meta: {
                tabTitle: TabTitles.REMINDER_TEMPLATES
            }
        },
        {
            path: prefix + 'template-editor/text/:id?',
            name: 'template-editor-text',
            component: TextTemplateEditor,
            props: true,
            meta: {
                tabTitle: TabTitles.MESSAGE_TEMPLATES
            }
        },
        {
            path: prefix + 'template-editor/text-reminder/:id?',
            name: 'template-editor-text-reminder',
            component: TextTemplateEditor,
            props: true,
            meta: {
                tabTitle: TabTitles.REMINDER_TEMPLATES
            }
        },
        {
            path: prefix + 'test',
            name: 'test',
            component: EmptyRouterLayout,
            children: [
                {
                    path: 'samples',
                    name: 'samples',
                    component: Samples
                },
                {
                    path: 'history-example',
                    name: 'history-example',
                    component: HistoryExample
                },
                {
                    path: 'samples2',
                    name: 'samples2',
                    component: Samples2
                },
                {
                    path: 'calendar-sample',
                    name: 'calendar-sample',
                    component: CalendarSample
                },
                {
                    path: 'chart-examples',
                    name: 'chart-examples',
                    component: ChartExamples
                }
            ]
        }
    ];

    if (mainContentOnly) {
        return mainContent;
    }

    return [
        {
            path: '/',
            name: 'login',
            component: LoginIdp,
            meta: {
                anonymous: true
            }
        },
        {
            path: '/',
            component: LoginLayout,
            meta: {
                anonymous: true
            },
            children: [
                {
                    path: 'login-direct',
                    name: 'login-direct',
                    component: LoginLLE,
                    meta: {
                        anonymous: true
                    }
                },
                {
                    path: 'forgot-username',
                    name: 'forgot-username',
                    component: ForgotUsername,
                    meta: {
                        anonymous: true
                    }
                },
                {
                    path: 'forgot-password',
                    name: 'forgot-password',
                    component: ForgotPassword,
                    meta: {
                        anonymous: true
                    }
                },
                {
                    path: 'verify-email',
                    name: 'verify-email',
                    component: VerifyEmail,
                    meta: {
                        anonymous: true
                    }
                },
                {
                    path: 'sso-reset',
                    name: 'sso-reset',
                    component: SsoResetPassword,
                    meta: {
                        anonymous: true
                    }
                },
                {
                    path: 'reset-password',
                    name: 'reset-password',
                    component: ResetPassword,
                    meta: {
                        auth: true
                    }
                },
                {
                    path: 'eula',
                    name: 'eula',
                    component: ConfirmEula,
                    meta: {
                        auth: true
                    }
                },
                {
                    path: 'recovery-email',
                    name: 'recovery-email',
                    component: SetRecoveryEmail,
                    meta: {
                        auth: true
                    }
                }
            ]
        },
        {
            path: '/code',
            name: 'code',
            component: SsoCode,
            meta: {
                anonymous: true,
                noLoginRedirect: true
            }
        },
        {
            path: '/logout',
            name: 'logout',
            component: Logout,
            meta: {
                auth: true,
                noLoginRedirect: true
            }
        },
        {
            path: '/main',
            component: LayoutWrapper,
            // nested routes
            children: mainContent
        },
        {
            path: '/nylas-code',
            name: 'nylas-code',
            component: NylasAuthRedirect,
            props: { isForCalendar: false }
        },
        {
            path: '/nylas-calendar-code',
            name: 'nylas-calendar-code',
            component: NylasAuthRedirect,
            props: { isForCalendar: true }
        },
        {
            path: '/tour-kiosk/:centerId',
            name: 'tour-kiosk',
            component: TourKiosk,
            props: true
        }
    ];
}

const routes = getRoutes();

const router = new VueRouter({
    routes
});

// guard against insecure access to secured routes
// basically for any route that isn't defined as anonymous, we check
// if they're logged in and if not we redirect them to the login page
router.beforeEach((to, from, next) => {
    if (!to.meta?.anonymous) {
        if (to.meta?.auth && authState.isAuthenticated) {
            next();
            return;
        }
        if (authState.isLoggedIn) {
            next();
            return;
        }
        const noLoginRedirect = to.meta?.noLoginRedirect ?? false;
        if (noLoginRedirect) {
            next({ name: 'login' });
        } else {
            next({
                name: 'login',
                query: { redirect: to.fullPath }
            });
        }
    } else {
        next();
    }
});

export default router;
